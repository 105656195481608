<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
        <h5 class="d-inline mr-2 font-weight-bold">{{$t('message.patient_indicator')}}</h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search"
            v-model="filterForm.search" clearable></el-input>
        </div>
      </div>

      <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset">
        <el-button v-can="'patientIndicators.create'" size="mini" @click="drawer.create.status = true"
          icon="el-icon-circle-plus-outline">
          {{$t('message.create')}}
        </el-button>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
      <thead>
        <tr>
          <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.patientIndicatorName" :sort="sort" @c-change="updateSort"></crm-th>
          <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort"></crm-th>
        </tr>

        <tr>
          <th v-if="columns.id.show">
            <el-input clearable size="mini" v-model="filterForm.id" class="id_input" :placeholder="columns.id.title">
            </el-input>
          </th>
          <th v-if="columns.patientIndicatorName.show">
            <el-input clearable size="mini" v-model="filterForm.name" :placeholder="$t('message.patient_indicator')">
            </el-input>
          </th>

          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="user in list" :key="user.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ user.id }}</td>
          <td v-if="columns.patientIndicatorName.show">{{ user.name }}</td>
          <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown :model="user" name="corpses" :actions="actions" @edit="edit" @delete="destroy">
            </crm-setting-dropdown>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-drawer size="60%" :wrapperClosable="false" :visible.sync="drawer.create.status" :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
      <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
    </el-drawer>

    <el-drawer size="60%" :wrapperClosable="false" :visible.sync="drawer.update.status" :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)" @closed="drawerClosed(drawer.update.component)">
      <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name">
      </crm-update>
    </el-drawer>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  // import CrmCreate from "./components/housing_modal";
  import CrmCreate from "./components/crm-create_patientIndicatorName";
  import CrmUpdate from "./components/crm-update_patientIndicatorName";
  import list from "@/utils/mixins/list";
  import drawer from "@/utils/mixins/drawer";
  export default {
    mixins: [list, drawer],
    name: "patentIndicator",
    components: {
      CrmCreate,
      CrmUpdate
    },
    data() {
      return {
        loadingData: false,
        selectedModel: {},
        drawer: {
          create: {
            name: "create",
            status: false,
            component: "componentDrawerCreate"
          },
          update: {
            name: "update",
            status: false,
            component: "componentDrawerUpdate"
          }
        }
      };
    },
    computed: {
      ...mapGetters({
        list: "patientIndicatorNames/list",
        columns: "patientIndicatorNames/columns",
        pagination: "patientIndicatorNames/pagination",
        filter: "patientIndicatorNames/filter",
        sort: "patientIndicatorNames/sort"
      }),
      actions: function () {
        return ["edit", "delete"];
      }
    },
    methods: {
      ...mapActions({
        updateList: "patientIndicatorNames/index",
        updateSort: "patientIndicatorNames/updateSort",
        updateFilter: "patientIndicatorNames/updateFilter",
        updateColumn: "patientIndicatorNames/updateColumn",
        updatePagination: "patientIndicatorNames/updatePagination",
        empty: "patientIndicatorNames/empty",
        delete: "patientIndicatorNames/destroy",
        refreshData: "patientIndicatorNames/refreshData"
      }),
      fetchData() {
        const query = {
          ...this.filter,
          ...this.pagination,
          ...this.sort
        };
        if (!this.loadingData) {
          this.loadingData = true;
          this.updateList(query)
            .then(res => {
              this.loadingData = false;
            })
            .catch(err => {
              this.loadingData = false;
            });
        }
      },
      refresh() {
        this.refreshData()
          .then(res => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch(err => {});
      },
      edit(model) {
        this.selectedModel = model;
        this.drawer.update.status = true;
      },
      async show(model) {
        await this.showModel(model.id)
          .then(res => {
            this.drawerShow = true;
          })
          .catch(err => {});
      },
      destroy(model) {
        this.delete(model.id)
          .then(res => {
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message
            });
            this.fetchData();
          })
          .catch(err => {
            console.log(err);
          });
      },
      emptyModel() {
        this.empty();
      }
    }
  };
</script>